import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function TurWriting() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">


               <p>
                    Yazma kısmı <span className = 'text-red-600'> {read.user.noq.writing} </span> soruları içerir ve her birini cevaplamak için 5 dakikanız vardır.Zamana ulaşılırsa ve cevabınızı göndermezseniz, uygulama otomatik olarak bir sonraki soruya geçer.
                </p>
                <br />
                <p>
                    Uygulamanın sağ üst kısmındaki ilgili soruların zamanlayıcıyı ve kameranızın sol üstte kaydedildiğini göreceksiniz.Yazma kısmı <span className = 'text-red-600'> Tüm soruları cevaplamak için toplam {read.user.noq.writing * 10} dakika alacaktır. </span> Lütfen zaman ayırın.
                </p>
                <br />
                <p>
                        Değerlendirmemizin kabul edilebilir aralığı dahilinde en az <span className = 'text-red-600'> 80 </span> kelimeleri yazmalısınız.Diğer kaynaklardan büyük veya küçük herhangi bir malzemeyi kopyalayamaz veya yapıştıramazsınız.
                </p>
                <br />
                <p>
                    Yazamıyorsanız, soruyu atlayabilir ve noktayı kaybedebilirsiniz.
                </p>
                <br />
                <p>
                    Monitörünüz, hareketiniz ve gözleriniz kaydediliyor.Üçüncü taraf bir başvuruyu açarsanız veya herhangi bir şekilde hile yaparsanız 0 alırsınız.
                </p>
                <br />
                <h1 className = 'text-2xl'>
                    Talimatı anlarsanız, yazma bölümüne basabilir ve başlayabilirsiniz.
                </h1>
            </div>

        </>
    )



}


export default TurWriting