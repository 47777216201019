import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function ItaWriting() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">


                <p>
                  La parte di scrittura include <span className = 'text-red-600'> {read.user.noq.writing} </span> e hai 5 minuti per rispondere a ciascuno di essi.Se viene raggiunto il tempo e non si invia la risposta, la domanda passerà automaticamente alla domanda successiva.
                </p>
                <br />
                <p>
                    Vedrai il timer per le domande correlate in alto a destra dell'applicazione e la fotocamera registrata in alto a sinistra.La parte di scrittura prenderà <span className = 'text-red-600'> Un totale di {read.user.noq.writing * 10} minuti per rispondere a tutte le domande. </span> Per favore prenditi il tuo tempo.
                </p>
                <br />
                <p>
                        È necessario scrivere almeno <span className = 'text-red-600'> 80 </span> Parole per rientrare nell'intervallo accettabile della nostra valutazione.Non è possibile copiare o incollare alcun materiale, grande o piccolo da altre fonti.
                </p>
                <br />
                <p>
                    Se non sei in grado di scrivere, puoi saltare la domanda e rinunciare al punto.
                </p>
                <br />
                <p>
                    Il monitor, il movimento e gli occhi vengono registrati.Riceverai uno 0 se apri un'applicazione di terze parti o imbrogliate in qualsiasi tipo di forma.
                </p>
                <br />
                <h1 className = 'text-2xl'>
                    Se capisci le istruzioni, puoi premere, capisco e avviare la parte di scrittura.
                </h1>

            </div>

        </>
    )



}


export default ItaWriting