import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function EngWriting() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">


                <p>
                    The writing part includes <span className=' text-red-600'>{read.user.noq.writing}</span> questions and you have 10 mins to answer each of them. if the time is reached and you do not submit your answer, the application will automatically move to the next question.
                </p>
                <br />
                <p>
                    You will see the timer for the related questions on the top right of the application and your camera being recorded on the top left. The writing part will take <span className=' text-red-600'> A TOTAL OF {read.user.noq.writing * 10} minutes to answer all of the questions.</span> Please take your time. 
                </p>
                <br />
                <p>
                        You must write at least <span className=' text-red-600'>80</span> words to be within the acceptable range of our assessment. You may not copy or paste any material, big or small from any other sources. If you use CHATgpt or any other AI tool or a translator, our system will easily detect it and you will be given a 0.
                </p>
                <br />
                <p>
                    If you are unable to write, you may skip the question and forfeit the point.
                </p>
                <br />
                <p>
                    Your monitor, movement and eyes are being recorded. You will receieve a 0 if you open a third party application or cheat in any sort of form.
                </p>
                <br />
                <h1 className=' text-2xl'>
                    If you understand the instruction, you may press I UNDERSTAND and start the writing part. Good Luck!
                </h1>

            </div>

        </>
    )



}


export default EngWriting