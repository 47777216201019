import React from 'react';
import { write, useSnap } from '../../store'
import toast from 'react-hot-toast';


function KorWriting() {
    const read = useSnap()


    return (

        <>

            <div className="flex flex-col text-justify">


                <p>
                    쓰기 부분에는 <span className='text-red-600'> {read.user.noq.writing} </span> 질문이 포함되며 각각에 답할 수있는 5 분이 있습니다.시간에 도달하고 답변을 제출하지 않으면 신청서가 자동으로 다음 질문으로 이동합니다.
                </p>
                <br />
                <p>
                    응용 프로그램 오른쪽 상단에 관련 질문에 대한 타이머와 왼쪽 상단에 카메라가 기록됩니다.쓰기 부분은 <span className='text-red-600'> a a {read.user.noq.writing * 10}의 총 질문에 답변합니다. </span> 시간을 보내십시오.
                </p>
                <br />
                <p>
                    최소한 <span className='text-red-600'> 80 </span> 단어를 우리의 평가의 허용 가능한 범위 내에 작성해야합니다.다른 소스에서 크거나 작은 재료를 복사하거나 붙여 넣을 수 없습니다.
                </p>
                <br />
                <p>
                    글을 쓸 수 없다면 질문을 건너 뛰고 요점을 상실 할 수 있습니다.
                </p>
                <br />
                <p>
                    모니터, 움직임 및 눈이 기록되고 있습니다.타사 신청서를 열거나 어떤 종류의 형태로 치트를 열면 0을 받게됩니다.
                </p>
                <br />
                <h1 className='text-2xl'>
                    당신이 지시를 이해하면, 당신은 내가 이해하고 글쓰기 부분을 시작할 수 있습니다.
                </h1>

            </div>

        </>
    )



}


export default KorWriting